// jQuery
(function ($) {
  $(function () {

    const $body = $('body');

    // Карусель
    $('[data-slider]:not(.js--slider-init)').addClass('js--init').each(function (index, element) {
      const $this = $(element);
      const data = $this.data('slider');

      $this.slick(data);
    });

    // Кирпичная кладка
    $('.js--masonry').masonry();

    // Закладки
    $body.on('click', '.js--tab', function (event) {
      event.preventDefault();

      const $this = $(this);
      const tab = $this.data('tab');

      $(tab).addClass('--active').siblings().removeClass('--active');

      $('.js--masonry').masonry();
    });

    //
    $body.on('click', '.js--scroll-to', function () {
      const $this = $(this);
      const selector = $this.attr('href');
      console.log(selector);
      const $selector = $(selector);
      const offset = $selector.offset().top;

      $('html, body').animate({ scrollTop: offset }, 200);

    });

  });
})(jQuery);
